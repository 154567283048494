.change_size{
  width:90%
}
  .srTopMenu {
    display: flex;
  }
  img{
    padding: 0%;
    margin: 0;
  }

  .site_information{
    height: 100%;
 
  }
  .changeIt{
    padding: 2.5%;
  }

  @media screen and (max-width: 768px) {
    .srTopMenu {
      flex-direction: column;

    }
  }
  @media screen and (max-width: 768px) {
    img {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .srTopMenu {
      width: 100%;
      text-align: center;
      align-items: center;
    }
  }
  