@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}

article h1 {
  margin: 1rem 0;
}
form{
    /* border: 2px solid black; */
    
}
label{
    color: black;
}
.form-group{
    text-align: center; 

    /* width: fit-content; */
    /* border: 2px solid black; */
    

}
.centerAlign{
    /* border: 2px solid black; */
    justify-content: center;

}
span{
    float: left;
    display: none;
}
.brand-name {
  color: rgb(255, 255, 255);
  font-size: 1.3rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  display: none;

  /* display: none; */
}
.addBorder{
  border:3px solid black
}
.top-header{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.fixHeight{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

}
.navigation {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #00008b;
    color: #000000;
    border-top: 0.5px solid black;
    border-bottom: 0.5px solid black;
    float:right;
  }
  .removeSpace{
    top: -60px;
  }
  .repositioning{
    position: absolute;
    right: 0;
    bottom: -60px;
  }

  .navigation-menu li a{
    color: #FFA500;
    font-size: 1.1rem;
  }

  

  .activateOnClick, .navigation-menu a:hover {
    color: #ffffff;
  }
  .parallelogram {
    width: 100px;
    position: absolute;
    height: 60px;
    left: -17px;
    transform: skew(30deg);
    background: #00008b;
    z-index: -1;
  }

  .navigation-menu {
    margin-left: auto;
  }
  
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  .navigation-menu li {
    /* // removes default disc bullet for li tags and applies margin to left & right side */
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    /* // increases the surface area of the anchor tag to span more than just the anchor text */
    text-decoration: none;
    display: block;
    width: 100%;
  }

  .logo_responsive{
    width: 75px;
    height: 60px;
    /* background-image: url("../../public/img/logo.jpg"); */
    background-size: 75px 60px;
    background-repeat: no-repeat;
    display: none;

  }

  .hamburger {
    /* // removes default border on button element */
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  /* // positions the icon to the right and center aligns it vertically */
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }

  .hamburger:hover {
    background-color: #ff9900;
  }
  @media screen and (max-width: 768px) {
    .parallelogram {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      /* // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
      position: absolute;
      top: 60px;
      left: 0;
      font-weight: bold;
      flex-direction: column;
      width: 100%;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      background-color: #00008B;
      border-top: 1px solid #FFA500;
      border-bottom: 1px solid #FFA500;
      z-index: 4;

    }
    .navigation-menu li {
      /* // centers link text and strips off margin */
      text-align: center;
      margin: 0;
      padding: 0;
    }
    .navigation-menu li a {
      color: #FFA500;
      /* // increases the surface area of the anchor tag to span the full width of the menu */
      width: 100%;
      /* padding: 1.5rem 0; */
      padding: 0.1rem;
    }
    .navigation-menu li:hover {
      background-color: #ff9900;
    }
  }
  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      /* previous styles */
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .brand-name {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    .logo_responsive {
      /* previous styles */
      display: block;
    }
  }

  /* @media screen and (max-width: 768px) {
    .brand-name {
      text-align: center;
    }
  } */
 
  
  @media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
      display: block;
    }
  }

.change_size{
  width:90%
}
  .srTopMenu {
    display: flex;
  }
  img{
    padding: 0%;
    margin: 0;
  }

  .site_information{
    height: 100%;
 
  }
  .changeIt{
    padding: 2.5%;
  }

  @media screen and (max-width: 768px) {
    .srTopMenu {
      flex-direction: column;

    }
  }
  @media screen and (max-width: 768px) {
    img {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .srTopMenu {
      width: 100%;
      text-align: center;
      align-items: center;
    }
  }
  
