form{
    /* border: 2px solid black; */
    
}
label{
    color: black;
}
.form-group{
    text-align: center; 

    /* width: fit-content; */
    /* border: 2px solid black; */
    

}
.centerAlign{
    /* border: 2px solid black; */
    justify-content: center;

}
span{
    float: left;
    display: none;
}